.chart-input-tooltip-container {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;

  .chart-input-tooltip {
    position: absolute;
    top: calc(var(--top) + var(--input-height));
    left: calc(var(--left) - calc(var(--tooltip-width) / 2) + calc(var(--input-width) / 2));
    transform: scale(0);
    margin: 5px 0 0;
    padding: 4px 8px;
    font-size: 11px;
    color: #031424;
    white-space: nowrap;
    opacity: 0;
    background: #EBEBEB;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
    transition:
      transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background 0.25s ease,
      color 0.25s ease;

    &.show {
      transform: scale(1);
      opacity: 1;
    }

    &.right-side {
      top: calc(var(--top) + calc(var(--input-height) / 2) - calc(var(--tooltip-height) / 2));
      left: calc(var(--left) + var(--input-width));
      margin: 0 0 0 5px;
    }
  }
}

.chart-input-container {
  display: flex;
  align-items: center;
  font-size: 14px;

  &.chart-input-container-text {
    flex-direction: column-reverse;
    row-gap: 3px;
    align-items: start;

    .chart-input-label {
      text-wrap: nowrap;
    }

    .chart-input-text {
      width: 100%;
      min-width: 50px;
      height: 100%;
      padding: 2px;
      font-size: 14px;
      color: #EBEBEB;
      background: #031424;
      border: none;
      border-radius: 5px;

      &:focus-visible {
        outline: 2px solid #6C3;
      }
    }
  }

  &.chart-input-container-range {
    flex-direction: column-reverse;
    row-gap: 3px;
    align-items: start;

    .chart-input-label {
      text-wrap: nowrap;
    }

    .chart-input-range {
      cursor: pointer;
      overflow: hidden;
      width: 100%;
      height: 10px;
      background-image: linear-gradient(90deg, #30415D, #2962FF);
      border: 1px solid #2962FF;
      border-radius: 16px;
      outline: none;
      -webkit-appearance: none;
      appearance: none;

      &::-webkit-slider-runnable-track {
        height: 10px;
        background-image: url('data:image/svg+xml,<svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="%232A2E39" fill-opacity="0.4" d="M0 0h4v4H0zM4 4h4v4H4z"/></svg>');
        border-radius: 16px;
      }

      &::-moz-range-track {
        height: 10px;
        background-image: url('data:image/svg+xml,<svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="%232A2E39" fill-opacity="0.4" d="M0 0h4v4H0zM4 4h4v4H4z"/></svg>');
        border-radius: 16px;
      }

      &::-webkit-slider-thumb {
        width: 8px;
        height: 8px;
        margin-top: 1px;
        background-color: #FFF;
        border-radius: 50%;
        -webkit-appearance: none;
        appearance: none;
      }

      &::-moz-range-thumb {
        width: 8px;
        height: 8px;
        margin-top: 1px;
        background-color: #FFF;
        border-radius: 50%;
      }
    }
  }

  &.chart-input-container-file {
    .chart-input-label-file {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      padding: 10px;
      border: 2px dashed #000;
      border-radius: 5px;
    }

    .chart-input-file {
      display: none;
    }
  }

  &.chart-input-container-checkbox {
    width: fit-content;

    &.full-width {
      width: 100%;
    }

    .chart-input-label-checkbox {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      column-gap: 5px;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;

      .checked {
        pointer-events: none;
        height: 20px;
      }

      .unchecked {
        pointer-events: none;
        height: 20px;
      }
    }

    .chart-input-checkbox {
      display: none;

      &:not(:checked) + .chart-input-label-checkbox .checked {
        display: none;

        rect {
          fill: #000;
          stroke: #000;
        }
      }

      &:checked + .chart-input-label-checkbox .unchecked {
        display: none;

        rect {
          fill: #000;
          stroke: #000;
        }
      }
    }
  }

  &.chart-input-container-checkbox.checkbox-button {
    flex-direction: column;

    .chart-input-label-checkbox {
      height: 100%;
      padding: 5px;
      color: #031424;
      background: #EBEBEB;
      border-radius: 0.25rem;
      transition: background 0.25s ease, color 0.25s ease;

      svg {
        pointer-events: none;
      }

      &:hover {
        background: #DDD;
      }

      &:active {
        background: #D3D3D3;
      }
    }

    .chart-input-checkbox {
      &:checked + .chart-input-label-checkbox {
        color: #379BE7;
      }
    }
  }
}
